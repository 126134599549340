import * as React from 'react';
import Hero from '../components/Elements/hero';
import Pills from '../components/Elements/pills';
import CTA from '../components/Elements/cta';
import Cards from '../components/Elements/cards';
import HelpCase from '../components/Elements/helpcase';
import Faq from '../components/Elements/faq';
import Snackybar from '../components/Elements/snackybar';
import CTANoImage from '../components/Elements/cta-noimage';

export default function IndexSignup() {
  return (
    <>
        <Hero type="Doctor" />
        {/*<Pills />*/}
        <CTANoImage />
        {/*<CTA />
         {/*<Cards /> 
        <HelpCase />*/}
        
        <Faq />
        {/*<Snackybar />*/}
    </>
  );
}