import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Curve from '../assets/curve.png';

export default function Salgsbetingelser() {
  return (
    <>
        <div className="gdpr">
            <div className="container">
                <div className="inner">
                    <div className="gdpr-header">
                        <h1>Salgsbetingelser Wifiskop</h1>
                        <p>Sist oppdatert: 25.03.2025</p>
                        <img className="curve" src={Curve} />
                    </div>
                    <div className="gdpr-body">
                        <div className="left">

                            <h3>Standard salgsbetingelser for forbrukerkjøp av tjenester fra Wifiskop AS</h3>
                        
                        <h3>Innledning</h3>

                        <p>Dette kjøpet er regulert av de nedenstående standard salgsbetingelser for forbrukerkjøp av tjenester over internett. Forbrukerkjøp av tjenester over internett reguleres hovedsakelig av avtaleloven, markedsføringsloven, angrerettloven og e-handelsloven, og disse lovene gir forbrukeren ufravikelige rettigheter. Vilkårene utfylles av relevante lovbestemmelser i ovennevnte lover og har forrang, med mindre motstriden gjelder ufravikelig regelverk. Lovene er tilgjengelig på www.lovdata.no. Vilkårene i denne avtalen skal ikke forstås som noen begrensning i de lovbestemte rettighetene, men oppstiller partenes viktigste rettigheter og plikter for handelen.</p>

                        <h3>1. Avtalen</h3>

                        <p>Avtalen består av disse salgsbetingelsene, opplysninger gitt i bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved eventuell motstrid mellom opplysningene, går det som særskilt er avtalt mellom partene foran, så fremt det ikke striden mot ufravikelig lovgivning. Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som regulerer kjøp av tjenester mellom næringsdrivende og forbrukere.</p>

                        <h3>2. Partene</h3> 

                        <p>Selger er Wifiskop AS, Stranden 89, 0250 Oslo, e-post mail@wifiskop.com, tlf: 41 49 04 14, org nr: 928 467 554, og betegnes i det følgende som selger/selgeren av tjenestene. Kjøper er den forbrukeren som foretar bestillingen / kjøpet av tjenestene, og betegnes i det følgende som kjøper / kjøperen.</p>



                        <h3>3. Pris</h3>

                        <p>Den oppgitte prisen for tjenestene er den totale prisen kjøper skal betale. Denne prisen inkluderer alle avgifter og tilleggskostnader. Ytterligere kostnader som selger før kjøpet ikke har informert om, skal kjøper ikke bære.</p>

                        <h3>4. Avtaleinngåelse</h3>

                        <p>Avtalen er bindenede for begge parter når kjøperen har sendt sin bestilling til selgeren. Avtalen er likevel ikke bindende hvis det har forekommet skrive- eller tastefeil i tilbudet fra selgeren i bestillingsløsningen i nettløsningen / plattformen eller i kjøpers bestilling, og den annen part innså eller burde ha innsett at det forelå en slik feil.</p>

                        <h3>5. Betalingen</h3>

                        <p>For Wifiskop AS sine tjenester kan kundene velge å betale med Vipps. Tjenesten blir belastet når tjenesten er utført.</p>

                        <h3>6. Angrerett</h3>

                        <p>Tjenesten er unntatt angrerettloven. Se spesielt §22 om leveringer av digitalt innhold som tas i bruk umiddelbart.</p>

                        <h3>7. Forsinkelse og manglende levering - Kjøpers rettigheter for å melde krav</h3>

                        <p>Dersom Wifiskop AS ikke leverertjenesten i henhold til avtalen mellom partene, og dette ikke skyldes kunden eller forhold på kundens side, kan kunden i henhold til reglene i forbrukerkjøpslovens kapittel 5 etter omstendighetene kreve refusjon fra WifiskopAS. Ved krav om misligholdsforføyelser bør meldingen av bevishensyn være skriftlig.</p>

                        <h3>Oppfyllelse</h3>

                        <p>Kjøper kan fastholde levering av tjenestene og kreve oppfyllelse fra selger. Kjøper kan imidlertid ikke kreve oppfyllelse dersom det vil medføre urimelig kostnad eller ulempe for selger sett opp mot kjøpers interesse i at selger utfører tjenestene. Kjøper taper sin rett til å kreve oppfyllelse om han eller hun venter urimelig lenge med å fremme kravet.</p>

                        <h3>Heving</h3>

                        <p>Kjøper kan heve avtalen dersom tjenestene ikke blir levert innen avtalt leveringstidspunkt. Dersom kjøper har krevd oppfyllelse av tjenestene, kan kjøper ikke heve avtalen dersom tjenestene blir utført innen rimelig tid eller innen avtalt frist.</p>

                        <h3>8. Mangel ved tjenestene - Kjøpers rettigheter og reklamasjonsfrist</h3>

                        <p>Hvis det foreligger en mangel ved tjenestene, må kjøper innen rimelig tid etter at den ble oppdaget eller burde blitt oppdaget, gi selger melding om at han eller hun vil påberope seg mangelen. Dersom tjenesten har en vesentlig mangel og dette ikke skyldes kunden eller forhold på kundens side, kan kunden i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter omstendighetene velge mellom retting, kreve prisavslag eller kreve avtalen hevet.</p>
                        <p>Reklamasjon til Wifiskop AS bør skje skriftlig.</p>

                        <h3>9. Personopplysninger</h3>

                        <p>Behandlingsansvarlig for innsamlede personopplysninger er selger. Med mindre kjøper samtykker til noe annet, kan selger, i tråd med personopplysningsloven, kun innhente og lagre de personopplysninger som er nødvendig for at selger skal kunne gjennomføre forpliktelsene etter avtalen. Kjøpers personopplysninger vil kun bli utlevert til andre hvis det er nødvendig i lovbestemte tilfelle.</p>

                        <h3>10. Konfliktløsning</h3>

                        <p>Klager rettes til selger innen rimelig tid, jf. Punkt 7 og 8. Partene skal forsøke å løse eventuelle tvister i minnelighet.</p>
               
                        </div> {/* LEFT */}
                        <div className="right">
                            <div className="box">
                                <h3>Har du spørsmål angående våre salgsbetingelser?</h3>
                                <p>Ta kontakt på vår e-post som er lenket nedenfor</p>
                                <p><a href="mailto:mail@wifiskop.com">mail@wifiskop.com</a></p>
                                <h4>Adresse: Stranden 89, 0250 Oslo</h4>
                            </div>
                        </div> {/* RIGHT */}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}