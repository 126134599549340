import * as React from 'react';
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import WifiPod from '../../assets/123-lg.png';
import Curve from '../../assets/curve.png';
import {ReactComponent as Signupimage} from '../../assets/hangup.svg';
import Login from '../Authentication/Login';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { fetchSignInMethodsForEmail } from 'firebase/auth';

export default function Hero(props) {
    const [open, setOpen] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false)
    const [formSendt, setFormSendt] = useState(false)
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [showPayment, setShowPayment] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // Mailchimp send form
    useEffect(() => {
        setCurrentLanguage(window.location.hostname === "wifiskop.com" ? "Norwegian" : "English")
        console.log("Dette er noe ::: ", window.location.hostname)
    }, [window.location.hostname])


    useEffect(() => {
      
        if(document.getElementById("newsletter")) {
          const newsletterForm = document.getElementById("newsletter");
       
          newsletterForm.addEventListener("submit", ((e) => {
            e.preventDefault()
      
            const data = new FormData(newsletterForm);
            const dataUrl = 'https://wifiskop.us10.list-manage.com/subscribe/post?u=9b4f25f3702adcbe3bf70a318&amp;id=0264f25d0f&amp;f_id=0077e3e5f0'
    
                  
            fetch(dataUrl, {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: data
                }).then(function(msg) {
                  newsletterForm.reset()
                    setFormSendt(true);
              }).catch(function(err) {
                console.log("Skjema er ikke sendt ::: ", err)
                
              });
          }))
        }
      }, [formLoaded])





  return (
    <>
        {props?.type === "Doctor" && (
            <>
                <div className="hero">
                    <div className="container">
                        <div className="inner-hero">
                            <div className="left">
                                
                                    <>
                                <h1>WifiSkop - Ditt
                                    <span> virtuelle</span> legesenter </h1>
                                <p className="lead">
                                    Velkommen til ditt virtuelle legesenter. Her kan du som 
                                    lege benytte deg av WifiSkop som ditt foretrukne virtuelle 
                                    arbeidsverktøy på nett helt kostnadsfritt.
                                    <br /><br />
                                    Du velger selv om du vil bruke wifiskop på heltid, deltid eller 
                                    av og til. 
                                    <br /><br />
                                    Er du lege og ønsker å registrere deg?
                                </p>
                                {/*<Login type={"Doctor"} /> */}
                                <Button
                                    sx={{mt: 2, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained"
                                    onClick={handleClickOpen}
                                >
                                    Få tilgang til wifiskop
                                    <ArrowForwardIcon sx={{ml: 1}} />
                                </Button>
                                    </>
                                
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={maxWidth}
                                    open={open}
                                    onClose={handleClose}
                                    className="heisannboks"
                                >

                    <div className="dialog-holder">
                        
                        

                        <div id="mc_embed_signup">
                            {!formSendt && (
                                <>
                                <h2>Velkommen til WifiSkop!</h2>
                                <p style={{ paddingBottom: '25px' }}>
                                Når WifiSkop er klart, vil du få en e-post med lenke, som du kan enkelt registrere deg sikkert med BankID.
                                </p>
                                <form method="post" onClick={() => {setFormLoaded(true)}} id="newsletter" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                        <div id="mc_embed_signup_scroll">
                                            
                                        <div class="mc-field-group">
                                    
                                        <label for="mce-EMAIL">E-post adresse  <span class="asterisk">*</span>
                                        </label>
                                                <input variant="outlined" label="E-post addresse" type="email" name="EMAIL" id="mce-EMAIL" required />
                                          

                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-FNAME">Fornavn  <span class="asterisk">*</span>
                                        </label>
                                            <input type="text" name="FNAME" class="required" id="mce-FNAME" required />
                                            <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-LNAME">Etternavn </label>
                                            <input type="text" name="LNAME" class="" id="mce-LNAME" />
                                            <span id="mce-LNAME-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group size1of2">
                                            <label for="mce-PHONE">Telefonnummer </label>
                                            <input type="text" name="PHONE" class="" id="mce-PHONE" />
                                            <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-ADRESSE">Adresse </label>
                                            <input type="text"  name="ADRESSE" class="" id="mce-ADRESSE" />
                                            <span id="mce-ADRESSE-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group size1of2">
                                            <label for="mce-MMERGE8-month">Fødselsdato </label>
                                            <div class="datefield">
                                                <span class="subfield dayfield">
                                                    <input style={{ width: '90px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="DD" size="2" maxlength="2" name="MMERGE8[day]" id="mce-MMERGE8-day" />
                                                </span> / 
                                                <span class="subfield monthfield">
                                                    <input style={{ width: '90px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="MM" size="2" maxlength="2" name="MMERGE8[month]" id="mce-MMERGE8-month" />
                                                </span> / 
                                                <span class="subfield yearfield">
                                                    <input style={{ width: '120px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="YYYY" size="4" maxlength="4" name="MMERGE8[year]" id="mce-MMERGE8-year" />
                                                </span>
                                                
                                            </div>
                                            <span id="mce-MMERGE8-HELPERTEXT" class="helper_text"></span>
                                        </div>	<div id="mce-responses" class="clear foot">
                                                <div class="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                                <div class="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                            </div> 
                                            <div aria-hidden="true">
                                                <input style={{ position: 'absolute', left: '-5000px', }} type="text" name="b_9b4f25f3702adcbe3bf70a318_0264f25d0f" tabindex="-1"  />

                                                </div>
                                                <label style={{maxWidth: '400px', margin: '25px auto 0', textAlign: 'center'}}>Ved å trykke registrer, har du lest, forstått og godkjent <Link target="__blank" to="/personvern" href="/personvern">GDPR vilkårene</Link> til WifiSkop AS </label>
                                                <div class="optionalParent">
                                                    <div class="clear foot" style={{ display: 'flex', justifyContent: 'center'}}>
                                                        <input type="submit" value="REGISTRER" name="subscribe" id="mc-embedded-subscribe" class="kaboom" />
                                                    </div>
                                                </div>
                                            </div>                            
                            
                                            
                                </form>

                                </>
                            )}

                            {formSendt &&
                                <>
                                    <h2>Takk for henvendelse</h2>
                                    <p>Når WifiSkop er klart, vil du få en e-post med lenke, som du kan enkelt registrere deg sikkert med BankID.</p>
                                    <Signupimage style={{ maxWidth: '100%', margin: '0px auto', height: 'auto', display: 'block', width: '100%' }} />
                                </>
                            }

                        </div>

                            
                    </div> {/* Dialog holder */}
                </Dialog>
                                

                            </div>
                            <div className="right">
                            <img src={WifiPod} />
                            </div>
                        </div>
                    </div>
                    <img className="curve" src={Curve} />
                </div>
            </>
        )}
        {props?.type === "Patient" && (
            <>
                <div className="hero">
                    <div className="container">
                        <div className="inner-hero">
                            <div className="left">
                                <h1>WifiSkop - Ditt <span>virtuelle</span> legesenter </h1>
                                <p className="lead">Velkommen til ditt virtuelle legesenter.</p>
                                <p className="lead">Her kan du som pasient bestille en videokonsultasjon med lege hele døgnet, hele året.</p>
                                <p className="lead">Er du lege og ønsker å behandle pasienter i ditt virtuelle legesenter WifiSkop kan du gjøre dette ved å registrere deg og bruke WifiSkop helt kostnadsfritt.</p>
                                
                                <div class="button-wrapper-hero">
                                    <label>
                                        <span>Er du pasient?</span>
                                        <Login type={"Patient"} />
                                    </label>
                                    <label>
                                        <span>Er du lege?</span>
                                        <Login type={"Doctor"} />
                                    </label>
                                </div>

                            </div>
                            <div className="right">
                                <img src={WifiPod} />
                            </div>
                        </div>
                    </div>
                    <img className="curve" src={Curve} />
                </div>
            </>
        )}
    </>
  );

}