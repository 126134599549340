import React, { useEffect, useState } from "react";
import { Select, MenuItem, Box } from "@mui/material";
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import Norflag from '../assets/norflag.png';
import UKFlag from '../assets/uk-flag.png';


const googleTranslateElementInit = () => {
  new window.google.translate.TranslateElement({
    pageLanguage: 'no',
    autoDisplay: false,
    includedLanguages: "no,en",
    layout:"google.translate.TranslateElement.InlineLayout.SIMPLE"
  }, 'google_translate_element');
};

const Translate = () => {
  const [selected, setSelected] = useState(window.location.hostname === "wifiskop.com" ? '/auto/no' : '/auto/en');

  const languages = [
    { label: 'Norsk', value: '/auto/no', flag: Norflag },
    { label: 'English', value: '/auto/en', flag: UKFlag },
  ];

  useEffect(() => {
    const addScript = window.document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    window.document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    const defaultLanguage = window.location.hostname === "wifiskop.com" ? '/auto/no' : '/auto/en';
    if (hasCookie('googtrans')) {
      setSelected(getCookie('googtrans'));
      
    } else {
      setCookie('googtrans', defaultLanguage);
      setSelected(defaultLanguage);
    }
  }, []);

  const langChange = (e, m, evt) => {
    if (evt) {
      evt.preventDefault();
    }

    if (hasCookie('googtrans')) {
      setCookie('googtrans', decodeURI(e));
    } else {
      setCookie('googtrans', e);
    }

    setSelected(e);
    window.location.reload(); // Reload the page to trigger translation update
  };

  return (

          <img style={{ width: '40px', height: '40px', objectFit: 'contain' }} src={Norflag} alt="Norwegian Flag" />

  );
};

export default Translate;
