import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import Norflag from '../../assets/norflag.png';
import UKFlag from '../../assets/uk-flag.png';
import { auth } from "../../firebase";
import Login from "../Authentication/Login";
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { signOut } from "firebase/auth";
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SvgIcon from "@mui/material/SvgIcon";
import Translate from '../translate';


import {useNavigate} from 'react-router-dom';

const currencies = [
    {
      value: "NO" 
    },
  ];

export default function Header(props) {

    

    const user = auth.currentUser;
    const navigate = useNavigate();
    const lastNameChar = user?.displayName.indexOf(',');
    const lastChar = user?.displayName[lastNameChar + 2];
    const firstChar = user?.displayName[0];
    const name = user?.displayName.replace(","," ");

  return (
    <>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className="header-bar" position="relative">
                <div className="container">
                    <div className="inner-header">
                        <div className="logo">
                            <Link to="/" href="/">
                                <Logo />
                            </Link>
                        </div>
                        <Box  sx={{ display: 'flex', alignItems: 'center', borderColor: 'white' }}>
    
                            <Translate />
                        {/*
                        <Select
                            sx={{
                                height: '45px',
                                padding: '15px 10px',
                                color: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white'
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'white'
                                }
                            }}
                            defaultValue="NO"
                            displayEmpty
                            renderValue={(value) => {
                            console.log(value);
                            return (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    {value === "NO" && <img style={{width: '24px', height: '24px', objectFit: 'contain'}} src={Norflag} />}
                          
                                    {value}
                                </Box>
                            );
                            }}
                        >
                            {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.value}
                            </MenuItem>
                            ))}
                            </Select> */}
                        </Box>
                        <div className="wpml">
                            {user && (
                                <>
                                <Button 
                                    sx={{ml: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained"
                                    onClick={() => {
                                        signOut(auth).then(() => {
                                            return navigate("/");
                                        }).catch((error) => {
                                            // An error happened.
                                        });
                                    }}>
                                    <LogoutIcon sx={{ mr: 1}} />
                                    Logg ut
                                </Button>
                                <div className="avatar-wrapper">
                                    <Avatar sx={{ width: 34, height: 34 }}>{firstChar}{lastChar}</Avatar>
                                   <p>{name}</p>
                                </div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </AppBar>
        </Box>
    </>
  );
}