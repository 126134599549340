import * as React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {ReactComponent as Signupimage} from '../../assets/hangup.svg';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Link } from 'react-router-dom';

export default function CtaNoImage() {
    const [currentLanguage, setCurrentLanguage] = useState()

    const [open, setOpen] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false)
    const [formSendt, setFormSendt] = useState(false)
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    useEffect(() => {
        setCurrentLanguage(window.location.hostname === "wifiskop.com" ? "Norwegian" : "English")
    }, [window.location.hostname])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
      
        if(document.getElementById("newsletter")) {
          const newsletterForm = document.getElementById("newsletter");
       
          newsletterForm.addEventListener("submit", ((e) => {
            e.preventDefault()
      
            const data = new FormData(newsletterForm);
            const dataUrl = 'https://wifiskop.us10.list-manage.com/subscribe/post?u=9b4f25f3702adcbe3bf70a318&amp;id=0264f25d0f&amp;f_id=0077e3e5f0'
    
                  
            fetch(dataUrl, {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: data
                }).then(function(msg) {
                  newsletterForm.reset()
                    setFormSendt(true);
              }).catch(function(err) {
                console.log("Skjema er ikke sendt ::: ", err)
                
              });
          }))
        }
      }, [formLoaded])


  return (
    <>
        <div className="cta no-image">
            <div className="container">
                <div className="inner-cta">
                    <>
                        <h3 style={{textAlign: "center"}}>Er du lege og ønsker å jobbe med WifiSkop?</h3>
                        
                            <p style={{maxWidth: "80%"}}>WifiSkop er et virtuelt behandlingsverktøy for leger og pasienter. </p>

                            <p>Ved å registrere deg i WifiSkop gir vi deg tilgang til alt det du trenger for å behandle 
                            pasienter med e-konsultasjoner. </p>

                            <p>Du vil uten forpliktelser få tilgang til videokonsultasjoner og journalløsning der du får mulighet til å behandle pasienter som bruker WifiSkop som sitt virtuelle legesenter 
                            når det passer deg. </p>
                            <p>Som lege kan du ved behov spe på din inntekt utover din vanlige jobb. Du velger selv hvor mye, 
                            når og hvor du ønsker å jobbe med Wifiskop.</p>

                            <p>WifiSkop vil i 2025 lansere et innovativt banebrytende verktøy som tilrettelegger for 
                            diagnostiske tester som kan enkelt integreres i den virtuelle konsultasjonen. </p>
                            
                            <p>Alt dette får du for kun kr 250,- som en engangsutgift.</p>

                            <p>Er du lege og ønsker å registrere deg?</p>
                        
                        <Button 
                            size="large"
                            variant="contained"
                            onClick={handleClickOpen}
                        >
                            Registrer deg
                            <ArrowForwardIcon sx={{ml: 2}} />
                        </Button>
                    </>
                    <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={maxWidth}
                                    open={open}
                                    onClose={handleClose}
                                    className="heisannboks"
                                >

                    <div className="dialog-holder">
                        
                        

                        <div id="mc_embed_signup">
                            {!formSendt && (
                                <>
                                <h2>Velkommen til WifiSkop!</h2>
                                <p style={{ paddingBottom: '25px' }}>
                                Når WifiSkop er klart, vil du få en e-post med lenke, som du kan enkelt registrere deg sikkert med BankID.
                                </p>
                                <form method="post" onClick={() => {setFormLoaded(true)}} id="newsletter" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                        <div id="mc_embed_signup_scroll">
                                            
                                        <div class="mc-field-group">
                                    
                                        <label for="mce-EMAIL">E-post adresse  <span class="asterisk">*</span>
                                        </label>
                                                <input variant="outlined" label="E-post addresse" type="email" name="EMAIL" id="mce-EMAIL" required />
                                          

                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-FNAME">Fornavn  <span class="asterisk">*</span>
                                        </label>
                                            <input type="text" name="FNAME" class="required" id="mce-FNAME" required />
                                            <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-LNAME">Etternavn </label>
                                            <input type="text" name="LNAME" class="" id="mce-LNAME" />
                                            <span id="mce-LNAME-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group size1of2">
                                            <label for="mce-PHONE">Telefonnummer </label>
                                            <input type="text" name="PHONE" class="" id="mce-PHONE" />
                                            <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group">
                                            <label for="mce-ADRESSE">Adresse </label>
                                            <input type="text"  name="ADRESSE" class="" id="mce-ADRESSE" />
                                            <span id="mce-ADRESSE-HELPERTEXT" class="helper_text"></span>
                                        </div>
                                        <div class="mc-field-group size1of2">
                                            <label for="mce-MMERGE8-month">Fødselsdato </label>
                                            <div class="datefield">
                                                <span class="subfield dayfield">
                                                    <input style={{ width: '90px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="DD" size="2" maxlength="2" name="MMERGE8[day]" id="mce-MMERGE8-day" />
                                                </span> / 
                                                <span class="subfield monthfield">
                                                    <input style={{ width: '90px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="MM" size="2" maxlength="2" name="MMERGE8[month]" id="mce-MMERGE8-month" />
                                                </span> / 
                                                <span class="subfield yearfield">
                                                    <input style={{ width: '120px' }} class="datepart " type="text" pattern="[0-9]*"  placeholder="YYYY" size="4" maxlength="4" name="MMERGE8[year]" id="mce-MMERGE8-year" />
                                                </span>
                                                
                                            </div>
                                            <span id="mce-MMERGE8-HELPERTEXT" class="helper_text"></span>
                                        </div>	<div id="mce-responses" class="clear foot">
                                                <div class="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                                <div class="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                            </div> 
                                            <div aria-hidden="true">
                                                <input style={{ position: 'absolute', left: '-5000px', }} type="text" name="b_9b4f25f3702adcbe3bf70a318_0264f25d0f" tabindex="-1"  />

                                                </div>
                                                <label style={{maxWidth: '400px', margin: '25px auto 0', textAlign: 'center'}}>Ved å trykke registrer, har du lest, forstått og godkjent <Link target="__blank" to="/personvern" href="/personvern">GDPR vilkårene</Link> til WifiSkop AS </label>
                                                <div class="optionalParent">
                                                    <div class="clear foot" style={{ display: 'flex', justifyContent: 'center'}}>
                                                        <input type="submit" value="REGISTRER" name="subscribe" id="mc-embedded-subscribe" class="kaboom" />
                                                    </div>
                                                </div>
                                            </div>                            
                            
                                            
                                </form>

                                </>
                            )}

                            {formSendt &&
                                <>
                                    <h2>Takk for henvendelse</h2>
                                    <p>Når WifiSkop er klart, vil du få en e-post med lenke, som du kan enkelt registrere deg sikkert med BankID.</p>
                                    <Signupimage style={{ maxWidth: '100%', margin: '0px auto', height: 'auto', display: 'block', width: '100%' }} />
                                </>
                            }

                        </div>

                            
                    </div> {/* Dialog holder */}
                </Dialog>
                                

                </div>
            </div>
        </div>
    </>
  );
}